<template>
    <div id="modal-container" class="uk-modal-container uk-modal" uk-modal ref="changelogModal">
        <div class="uk-modal-dialog uk-modal-body">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <h2 class="uk-modal-title uk-margin-remove-bottom">Changelog</h2>
            <h4 class="uk-margin-large-bottom uk-margin-remove-top">Current version: {{log[0].version}}</h4>
            <div class="changelog-content" uk-overflow-auto>

                <div class="version-log" v-for="(version, index) of log" :key="version.version">
                    <h4><strong>Version {{version.version}}</strong><br><span>{{version.date}}</span></h4>
                    <div class="version-note" v-if="version.note">
                        <strong>Version note: </strong>
                        <div v-html="version.note"></div>
                    </div>
                    <ul class="uk-list ">
                        <span v-if="version.changes.length < 1">No changes yet.</span>
                        <li v-for="change in version.changes" :key="change">
                            <div class="description uk-flex uk-flex-middle">
                                <span uk-icon="icon:close;ratio:1" class="uk-display-inline-block uk-text-danger"  v-if="change.type === 0"></span>
                                <span uk-icon="icon:cog;ratio:1"   class="uk-display-inline-block "                v-if="change.type === 1"></span>
                                <span uk-icon="icon:plus;ratio:1"  class="uk-display-inline-block uk-text-success" v-if="change.type === 2"></span>
                                <span class="uk-display-inline-block uk-margin-small-left" style="position:relative;top: -2px;"> <strong>{{featureTypes[change.type]}}</strong> - <span> {{change.subject}}</span></span>

                            </div>
                            <span style="margin-left: calc(var(--global-margin) * 1.5)"><strong>Page: </strong> {{change.page || 'not set'}}</span><br>
                            <p class="uk-margin-remove-top"
                               style="margin-bottom: calc(var(--global-margin) * 1.5); margin-left: calc(var(--global-margin) * 1.5)">{{change.text}}</p>
                        </li>
                    </ul>
                    <hr>
                </div>

                21.02.21 - log begins



            </div>
         </div>
    </div>
    <div class="uk-container  uk-position-relative uk-background-inverse uk-container-expand uk-margin-auto uk-text-center uk-flex uk-flex-canter uk-flex-middle">
        <div class="uk-flex uk-margin-auto uk-flex-middle" @click="showChangelog" style="cursor: pointer;">
            <span>Dev Version {{log[0].version}}</span><span class="uk-margin-small-left" uk-icon="question"></span>
        </div>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                featureTypes: {
                    0: 'Bug Fixed',
                    1: 'Modification',
                    2: 'Feature',
                },
                log: [
                    {
                        date: '05.05.2021',
                        version: '0.0.017',
                        note: 'Remaining known tasks: <br>' +
                            '1. Accounts page - visual account explorer implementation.<br>' +
                            '2. Contact page - different map implementation <br>' +
                            '3. Manage subscription page - add features for resources, finish features for display<br>' +
                            '4. My subscriptions page - make all buttons functional<br>' +
                            '5. Ecommerce homepage<br>' +
                            '6. Usage details graphs<br>' +
                            '7. Blocked pages: change offer, transfer data, groups, games, ecommerce goodies, ecommerce front page, support mini-site, ecommerce subscriptions master category. <br><br>' +
                            'Various other tasks, such as new dashboard feedback, mobile comments, etc. are known. This list covers only major issues.',
                        changes: [
                            {
                                subject: 'Accounts page modification - future',
                                type: 1,
                                page: '#/accounts',
                                text: "NOTICE: improved account diagram, with subscriptions will be included in final implementation. Solution based on https://visjs.github.io/vis-network/examples/",
                            },
                            {
                                subject: 'Accounts page modification - future',
                                type: 1,
                                page: '#/accounts',
                                text: "NOTICE: if required, a similar payment diagram will be added in final implementation",
                            },
                            {
                                subject: 'Accounts - nester articles',
                                type: 1,
                                page: '#/accounts',
                                text: "Articles now nested inside articles card, instead of replacing it",
                            },
                            {
                                subject: 'Accounts - subscription list',
                                type: 1,
                                page: '#/accounts',
                                text: "Changed subscription list styling on the right",
                            },
                            {
                                subject: 'Accounts - sub account styling',
                                type: 1,
                                page: '#/accounts',
                                text: "Now similar to invoice style from #/invoices",
                            },

                            {
                                subject: 'Accounts - edit label & permissions',
                                type: 2,
                                page: '#/accounts',
                                text: "Added UI to edit label and permissions, when the user is permitted to do it. NOTICE: for demo purposes, the demo user can not access the 'client' root account, but can still change permissions below it. This is only for demo purposes.",
                            },

                            {
                                subject: 'Tariffs - style changes',
                                type: 1,
                                page: '#/tariffs',
                                text: "Added gentle gradient, made sure all boxes stretch",
                            },
                            {
                                subject: 'Tariffs - abbreviation clarifications',
                                type: 2,
                                page: '#/tariffs',
                                text: "Added abbreviation clarifications bellow recharge rates",
                            },
                            {
                                subject: 'Side menu - styles',
                                type: 1,
                                page: 'All selfcare pages, /#',
                                text: "changed panel indicator color. Notice a similar color to what was requested is used, to match color pallet",
                            },
                            {
                                subject: 'Loyalty - style changes',
                                type: 1,
                                page: '#/loyalty',
                                text: "History timeline bubbles CTA now right floating button. Upsales demo scroller",
                            },
                            {
                                subject: 'Contact page - map',
                                type: 2,
                                page: '#/contact',
                                text: "Map covers the top. NOTICE: for now using google maps for demo. We will attempt to work with the map you requested in the actual product.",
                            },
                            {
                                subject: 'Contact page - vendor list',
                                type: 2,
                                page: '#/contact',
                                text: "Filterable vendor list added on the right, with address and opening hours. NOTICE: the select field will be searchable.",
                            },
                            {
                                subject: 'Contact page - vendor list - expanded view',
                                type: 2,
                                page: '#/contact, then click "more info" on any vendor',
                                text: "Full vendor one pager that can take on more information later on if we want.",
                            },
                            {
                                subject: 'Contact page - vendor notifications',
                                type: 2,
                                page: '#/contact, then click "get notifications" or "disable notifications"',
                                text: "UI to register oneself to vendor notifications. Once registered, can be turned off in the same way. Confirmation before both activation and cancelation.",
                            },
                            {
                                subject: 'Ecommerce page - subscription category',
                                type: 2,
                                page: '#/shop/category/offers/prestige',
                                text: "Demo for subscription category, offering several subscriptions.",
                            },
                            {
                                subject: 'Selfcare - logo effect',
                                type: 2,
                                page: 'All selfcare pages',
                                text: "Hover effect on top logo.",
                            },
                            {
                                subject: 'Payment interface demo',
                                type: 2,
                                page: '#/invoices, then click to pay',
                                text: "Demo for a possible payment flow. NOTICE: it may be that in the final version, the initial window and the payment method selection + summary will be separated into two stages. NOTICE: demo shows only final result for full payment, in finished version partial payment fully supported",
                            },
                            {
                                subject: 'Cookie explain popup',
                                type: 2,
                                page: '#/cookie-popup',
                                text: "Demo for a possible cookie acceptance popup. Notice: you must refresh the page with the URL entered in the demo in order to get the popup",
                            },
                            {
                                subject: 'Disconnect session warning popup',
                                type: 2,
                                page: '#/disconnect-warning',
                                text: "Popup that will appear after inactivity. Notice: you must refresh the page with the URL entered in the demo in order to get the popup",
                            },
                        ]
                    }
                ]
            };
        },
        methods: {
            showChangelog() {
                return this.openChangelogModal();
            },
            openChangelogModal () {
                this.$s.ui.modal(this.$refs.changelogModal).show();
            }
        },
        mounted() {
          //  this.showChangelog();
        }

    }
</script>

<style scoped lang="scss">

</style>
